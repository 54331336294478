export default{
    useNotice:[
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'印章管理员',str5:'-',str6:'-',status:1},
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'印章管理员',str5:'2022-12-21 12:22:00',str6:'2022-12-22 12:22:00',status:1},
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'印章管理员',str5:'2022-12-21 12:22:00',str6:'2022-12-22 12:22:00',status:2},
    ],//使用权限假数据
    applicationNotice:[
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'签署商品订购合同',str5:'2022-12-21 12:22:00',str6:'2022-12-22 12:22:00',status:1},
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'签署商品订购合同',str5:'2022-12-21 12:22:00',str6:'2022-12-22 12:22:00',status:2},
        {str1:'余聪',str2:'12944556655',str3:'人事部',str4:'签署商品订购合同',str5:'2022-12-21 12:22:00',str6:'2022-12-22 12:22:00',status:3},
    ],//印章申请假数据
    operationNotice:[
        {str1:'余聪（137****2233）',str2:'查看了宁波夏天信息科技有限公司',str3:'2022-12-22 12:22:22'},
        {str1:'余聪（137****2233）',str2:'查看了宁波夏天信息科技有限公司',str3:'2022-12-22 12:22:22'},
        {str1:'余聪（137****2233）',str2:'查看了宁波夏天信息科技有限公司',str3:'2022-12-22 12:22:22'},
        {str1:'余聪（137****2233）',str2:'查看了宁波夏天信息科技有限公司',str3:'2022-12-22 12:22:22'},
    ],//操作记录详情数据
    realnameRecordNotice:[
        {str1:123791872398789713,str2:'余聪',status:0,str3:'2023-01-30 13:55'},
        {str1:123791872398789713,str2:'余聪',status:1,str3:'2023-01-30 13:55'},
        {str1:123791872398789713,str2:'余聪',status:2,str3:'2023-01-30 13:55'},
        {str1:123791872398789713,str2:'余聪',status:3,str3:'2023-01-30 13:55'},
    ],//实名认证记录假数据
}